<template>
  <section>
    <div v-if="loading" class="loading-spinner_container">
      <LoadingSpinner />
    </div>

    <div v-if="showWarning">
      <EventWarnings :warning="warning" />
    </div>

    <header class="service-header" v-if="!loading">
      <div class="service-details">
        <span class="vendor-name">{{ vendor.name }}</span>
        <div
          class="description"
          :class="[
            {
              long: ifVendorDescriptionTooLong,
            },
            { trucated: ifVendorDescriptionTrucated },
          ]">
          <p ref="vendor-description">
            {{ vendor.description }}
          </p>
          <span
            class="more"
            @click="this.toggleVendorDescription"
            v-if="ifVendorDescriptionTooLong">
            {{ ifVendorDescriptionTrucated ? "more" : "Show less" }}</span
          >
        </div>
      </div>

      <div class="service-date-picker">
        <h3 class="service-heading">Select a date</h3>
        <div class="service-selector" v-if="this.getServices().length > 0">
          <button
            :class="`day ${date == currentDate ? 'current' : 'outlined'}`"
            v-for="date of this.getDates()"
            :key="date">
            <div @click="this.selectDate(date)">
              <h3>{{ formatDate(date, "dd/MM") }}</h3>
              <span>{{ formatDate(date, "dddd") }}</span>
            </div>
          </button>
        </div>
        <NoDataText v-else text="No Upcoming Services" />
      </div>
    </header>

    <div class="service" v-if="!loading && this.currentDate != null">
      <div>
        <h3 class="service-heading">Select a location</h3>
        <ul class="service-list">
          <li
            v-for="service in this.getCurrentServices()"
            :key="service.id"
            class="service-list_item card"
            :class="{
              closed:
                service.status == 'Finished' || service.status == 'Cancelled',
              carousel: getThumbnail(service.thumbnails).length > 0,
            }">
            <div
              v-if="
                service.status == 'Finished' || service.status == 'Cancelled'
              "
              class="inactive flex-center">
              <h2>Closed</h2>
            </div>
            <ServiceLink
              :service="service"
              class="service-list_link"
              :disabled="
                service.channels.length == 1 &&
                service.channels[0].name == 'WalkUp'
              ">
              <div class="image-container_outer">
                <div
                  class="image-container_inner"
                  :class="{
                    empty: getThumbnail(service.thumbnails).length === 0,
                  }">
                  <ImageCarousel
                    is-clickable
                    :assets="getThumbnail(service.thumbnails)"
                    :alt="service.venue.name"
                    @click="routerPush(service)" />
                </div>
              </div>

              <div
                class="service-list_link_content_outer"
                @click="routerPush(service)">
                <div
                  class="busyness-indicator_outer"
                  v-if="
                    availableTimes[service.id] != null &&
                    service.status != 'Finished'
                  ">
                  <div class="busyness-indicator_inner flex-center">
                    <Icon :path="mdiCellphoneLink" :size="15" />
                    <span v-if="!serviceIsOpen[service.id]" class="time-range">
                      Opens at {{ LocalTime.parse(service.startTime) }}
                    </span>
                    <span
                      class="time-range"
                      v-else-if="availableTimes[service.id] > 0"
                      >{{ availableTimes[service.id] }} min</span
                    >
                    <span class="time-range" v-else>No wait</span>
                  </div>
                </div>

                <div class="service-list_link_content_inner">
                  <h4 class="vendor-name">
                    {{ replace(service.name, service.venue.name) }}
                  </h4>

                  <p v-if="service.cuisines.length" class="outlet-name">
                    <Icon
                      :path="mdiTruck"
                      :size="16"
                      class="service-list-icon" />
                    {{ service.outlet.name }}
                  </p>

                  <p v-if="service.cuisines.length" class="cuisines">
                    <Icon
                      :path="mdiMapMarker"
                      :size="16"
                      class="service-list-icon" />
                    {{
                      `${service.address.addressLine1 ?? ""}, ${
                        service.address.addressLine2 ?? ""
                      }, ${service.address.city ?? ""}, ${
                        service.address.postalCode ?? ""
                      }`
                    }}
                  </p>

                  <p v-if="service.cuisines.length" class="cuisines">
                    <Icon
                      :path="mdiFood"
                      :size="16"
                      class="service-list-icon" />
                    {{
                      service.outlet.cuisines
                        .sort((a, b) => b.id - a.id)
                        .map((c) => c.name)
                        .join(", ")
                    }}
                  </p>

                  <!-- <p class="vendor-description">{{ service.description }}</p> -->
                  <p class="service-times">
                    <Icon
                      :path="mdiClock"
                      :size="16"
                      class="service-list-icon" />
                    {{ formatTime(service.startTime) }} -
                    {{ formatTime(service.endTime) }}
                  </p>
                  <p class="channels">
                    <Icon
                      :path="mdiShopping"
                      :size="16"
                      class="service-list-icon" />
                    {{ formatChannels(service.channels).join(", ") }}
                  </p>
                </div>
                <div
                  class="call-to-action"
                  v-if="
                    (service.channels.length == 1 &&
                      service.channels[0].name == 'WalkUp') ||
                    service.isOrderingAvailable === false
                  ">
                  View Menu
                </div>
                <div v-else class="call-to-action">Order Now</div>
              </div>
            </ServiceLink>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
  import store from "@/store";
  import { mapGetters } from "vuex";
  import EventWarnings from "@/components/EventWarnings";
  import Enumerable from "linq";
  import { LocalTime, LocalDate, ChronoUnit } from "@js-joda/core";
  import { formatChannels } from "@/helpers/formatChannel";
  import { replace } from "@tucktrucks/core";
  import {
    mdiFood,
    mdiClock,
    mdiShopping,
    mdiCellphoneLink,
    mdiMapMarker,
    mdiTruck,
  } from "@mdi/js";
  import NoDataText from "@/components/NoDataText";
  import ImageCarousel from "@/components/Images/ImageCarousel.vue";
  import ServiceLink from "@/components/Router/ServiceLink.vue";

  export default {
    data() {
      return {
        outletCode: this.$route.params.outletCode,
        outlet: null,
        vendor: null,
        outletsLoading: false,
        loading: false,
        services: [],
        currentDate: null,
        serviceNotFound: false,
        showWarning: false,
        warning: "",
        availableTimes: [],
        serviceIsOpen: [],
        ifVendorDescriptionLoaded: false,
        ifVendorDescriptionTrucated: false,
        ifVendorDescriptionTooLong: false,
        mdiFood,
        mdiClock,
        mdiShopping,
        mdiCellphoneLink,
        mdiMapMarker,
        mdiTruck,
        LocalTime,
        replace,
      };
    },

    computed: {
      ...mapGetters({
        repoGetServiceById: "repoServices/getById",
        getOutletById: "repoOutlets/getById",
        getVendorById: "repoVendors/getById",
      }),
    },

    methods: {
      formatChannels,

      async getOutletCuisine(outletId) {
        try {
          const response =
            await store.state.apiPublic.client.endpoints.outlets.getbyId(
              outletId
            );
          return response.data.data.cuisineIds;
        } catch (error) {
          window.log(`Failed to get cuisineIds for outlet ${outletId}`, error);
        }
      },

      getThumbnail(thumbnails) {
        let thumbnailSrc = [];

        if (thumbnails?.service?.length > 0) {
          for (const thumbnail of thumbnails?.service) {
            thumbnailSrc.push(
              `${process.env.VUE_APP_CDN}` + "/" + thumbnail.cdnUrl
            );
          }
        } else if (thumbnails?.servicePrototype?.length > 0) {
          for (const thumbnail of thumbnails?.servicePrototype) {
            thumbnailSrc.push(
              `${process.env.VUE_APP_CDN}` + "/" + thumbnail.cdnUrl
            );
          }
        } else if (thumbnails?.outlets?.length > 0) {
          for (const thumbnail of thumbnails?.outlets) {
            thumbnailSrc.push(
              `${process.env.VUE_APP_CDN}` + "/" + thumbnail.cdnUrl
            );
          }
        } else if (thumbnails?.vendors?.length > 0) {
          for (const thumbnail of thumbnails?.vendors) {
            thumbnailSrc.push(
              `${process.env.VUE_APP_CDN}` + "/" + thumbnail.cdnUrl
            );
          }
        }

        // temp reduction to single array item

        if (thumbnailSrc.length == 0) {
          return [];
        }

        return [thumbnailSrc[0]];
      },

      getServices() {
        return Enumerable.from(this.services)
          .orderBy((x) => x.startDate)
          .toArray();
      },

      getDates() {
        return Enumerable.from(this.services)
          .select((x) => x.startDate)
          .distinct()
          .orderBy((x) => x)
          .toArray();
      },

      getServiceName(date) {
        return Enumerable.from(this.services).firstOrDefault(
          (x) => x.startDate == date
        ).name;
      },

      getCurrentServices() {
        const todayServices = this.services.filter(
          (x) => x.startDate == this.currentDate
        );

        const todayServicesOpen = todayServices
          .filter(
            (service) =>
              service.status != "Finished" && service.status != "Cancelled"
          )
          .sort((a, b) => a.vendor.id > b.vendor.id);

        const todayServicesClosed = todayServices
          .filter(
            (service) =>
              service.status == "Finished" || service.status == "Cancelled"
          )
          .sort((a, b) => a.vendor.id > b.vendor.id);

        return todayServicesOpen
          .sort((a, b) => {
            if (a.outlet?.name > b.outlet?.name) {
              return 1;
            }
            if (a.outlet?.name < b.outlet?.name) {
              return -1;
            }
            if (a.startTime > b.startTime) {
              return 1;
            }
            if (a.startTime < b.startTime) {
              return -1;
            }
            return 0;
          })
          .concat(
            todayServicesClosed.sort((a, b) => {
              if (a.outlet?.name > b.outlet?.name) {
                return 1;
              }
              if (a.outlet?.name < b.outlet?.name) {
                return -1;
              }
              if (a.startTime > b.startTime) {
                return 1;
              }
              if (a.startTime < b.startTime) {
                return -1;
              }
              return 0;
            })
          );
      },

      getDistinctVendors() {
        return Enumerable.from(this.services)
          .distinct((x) => x.vendor.id)
          .orderBy((x) => x.name)
          .toArray();
      },

      getDistinctDates() {
        return Enumerable.from(this.services)
          .select((x) => x.startDate)
          .distinct()
          .orderBy()
          .toArray();
      },

      toggleVendorDescription() {
        this.ifVendorDescriptionTrucated = !this.ifVendorDescriptionTrucated;
      },

      selectDate(date) {
        this.currentDate = date;
      },

      getNextTimes(serviceId) {
        return store.state.apiPublic.client.endpoints.services
          .getNextTime(serviceId)
          .then((response) => {
            if (response.status == 200) {
              return response.data.data;
            }

            if (response.status == 204) {
              return null;
            }

            return Promise.reject("Failed to find next times.");
          });
      },

      routerPush(service) {
        if (
          service.channels.length == 1 &&
          service.channels[0].name == "WalkUp"
        ) {
          return;
        } else {
          this.$router.push("/service/" + service.id);
        }
      },

      async fetchOutlet() {
        const response =
          await store.state.apiPublic.client.endpoints.outlets.getbyCode(
            this.outletCode
          );
        this.outlet = response.data.data;
        this.vendor = this.getVendorById(this.outlet.vendorId);
      },

      async fetchData() {
        this.$emit("cartHidden");

        //const vendorId = this.vendor.id;
        const outletCode = this.outlet.code;

        const mode = this.$route.meta.mode;

        switch (mode) {
          default:
          case "upcoming":
            store.state.apiPublic.client.endpoints.outlets
              .getUpcomingServicesCode(outletCode)
              .then((response) => {
                if (response.status == 200) {
                  return response.data.data;
                } else {
                  window.log.error("Could not download upcoming services.");
                  this.warning = "Could not download upcoming services.";
                  this.showWarning = true;
                }
              })
              .then(async (services) => {
                this.services = [];

                if (services.length > 0) {
                  let first = true;

                  for (const service of services) {
                    if (service.startDate == null) {
                      continue;
                    }

                    const nowDate = LocalDate.now();
                    const serviceDate = LocalDate.parse(service.startDate);

                    const serviceOutletId = service.outlet.id;
                    const fetchedOutletCuisines = this.services.find(
                      ({ outlet }) => outlet.id === serviceOutletId
                    )?.cuisines;

                    if (!fetchedOutletCuisines) {
                      const outletCuisines = await this.getOutletCuisine(
                        serviceOutletId
                      );
                      service.outlet["cuisines"] = outletCuisines;
                    } else {
                      service.outlet["cuisines"] = fetchedOutletCuisines;
                    }

                    if (!nowDate.isEqual(serviceDate)) {
                      this.availableTimes[service.id] = null;
                    } else {
                      // check if service is open
                      const startTime = LocalTime.parse(service.startTime);
                      const timeNow = LocalTime.now();

                      if (timeNow.isBefore(startTime)) {
                        this.availableTimes[service.id] = 0;
                        this.serviceIsOpen[service.id] = false;
                      } else {
                        const nextString = await this.getNextTimes(service.id);
                        if (nextString != null) {
                          const next = LocalTime.parse(nextString);
                          let minutes = timeNow.until(next, ChronoUnit.MINUTES);

                          // If the queue is short (< 5) then lock it off to 0 - the UI then reflects that as "no queue"
                          if (minutes < 5) {
                            minutes = 0;
                          }

                          // Round it to the nearest 5 minutes.
                          minutes = Math.round(minutes / 5) * 5;

                          this.availableTimes[service.id] = minutes;
                          this.serviceIsOpen[service.id] = true;
                        } else this.availableTimes[service.id] = null;
                      }
                    }

                    if (first) {
                      this.currentDate = serviceDate;
                    }

                    this.services.push(service);
                    first = false;
                  }
                }

                this.loading = false;
              });
            break;
        }
      },
    },

    async beforeMount() {
      this.loading = true;
      await this.fetchOutlet();
      await this.fetchData();
    },

    components: {
      EventWarnings,
      NoDataText,
      ImageCarousel,
      ServiceLink,
    },
  };
</script>

<style lang="scss" scoped>
  section {
    padding: 5%;
  }

  header.service-header {
    top: $topbar_height;
    z-index: 9;
    background: $col_offwhite;
    display: block;

    .mobileLayout & {
      width: 100%;
      left: 0;
      padding: 1rem 0;
      position: fixed;

      .service-heading {
        padding-left: 10vw;
      }
    }

    .description {
      overflow: hidden;
      padding-right: 2rem;
      line-height: 1.5em;
      padding-left: 0.15rem;

      p {
        height: initial;
        overflow: visible;
        transition: 0.2s height;
        line-height: 1.5em;
        padding-bottom: 0.5rem;
      }
      .more {
        text-decoration: underline;
        cursor: pointer;
        font-size: 0.8rem;
        line-height: 1.1em;
        color: $col_faded;
      }

      &.trucated {
        p {
          height: 37px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }

      &.long {
        p {
          padding-bottom: 0;
        }
      }
    }
    .more-detail {
      cursor: pointer;
    }
  }

  .service-date-picker {
    .mobileLayout & {
      width: 100%;
      left: 0;
      padding: 0;
      .service-heading {
        padding-left: 10vw;
      }
    }
  }

  .service-details {
    display: block;
    margin-bottom: 0.5rem;

    .mobileLayout & {
      padding: 0 10vw;
      font-size: 0.875rem;
    }
  }

  .service-heading {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;

    .mobileLayout & {
      margin-bottom: 0.25rem;
    }
  }

  .service-selector {
    width: 100%;
    height: 90px;
    white-space: nowrap;

    overflow-x: auto;
    overflow-y: hidden;
    margin-right: -350px;
    margin-bottom: 1rem;

    .mobileLayout & {
      margin-bottom: 0;
      padding-right: 2.5rem;
      padding-left: 10vw;
      height: auto;
      padding-bottom: 1rem;
    }
  }

  .day {
    padding: 10px;
    float: none;
    width: 140px;
    min-width: auto;
    margin-right: 1rem;
    display: inline-block;
    zoom: 1;
    padding: 0.7rem 1rem;
    border-radius: 50px;
    color: $col_white;

    .mobileLayout & {
      padding: 0.35rem 0.5rem;
      margin-right: 0.5rem;
    }

    h3 {
      margin: 0;
      font-weight: 500;
      line-height: 1.6rem;
    }

    span {
      display: block;
      white-space: nowrap;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1rem;
    }
    &.current {
      background: $col_beta-darker;
      color: $col_white;

      &:hover {
        background: $col_beta-darker;
        color: $col_white;
      }
    }
  }

  .service {
    display: block;
    width: 100%;
    margin-top: 1rem;

    h2 {
      margin-bottom: 0;
    }

    .mobileLayout & {
      margin-top: 15rem;
    }
  }

  .service-list {
    margin: 0;
    padding: 0.15rem 0 0 0;
    width: 100%;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(3, 1fr);

    &_link {
      height: 100%;
    }
  }

  @media only screen and (max-width: 639px) {
    .service-list {
      gap: 1.5rem;
      grid-template-columns: repeat(1, 1fr);
    }
  }

  @media only screen and (min-width: 640px) {
    .service-list {
      gap: 1.5rem;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media only screen and (min-width: 961px) {
    .service-list {
      gap: 2rem;
      grid-template-columns: repeat(3, 1fr);

      .cart-view & {
        grid-template-columns: 1fr 1fr;
      }
    }
  }

  @media only screen and (min-width: 1920px) {
    .service-list {
      grid-template-columns: repeat(5, 1fr);
    }
  }

  .service-list_item {
    width: 100%;
    cursor: pointer;
    height: initial !important;
    padding: 0 !important;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.closed {
      pointer-events: none;
      user-select: none;
      position: relative;
    }

    .inactive {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 2;
      background: rgba(123, 123, 123, 0.5);
      backdrop-filter: blur(4px);
      h2 {
        color: #fff;
      }
    }

    .service-link {
      text-decoration: none;
      color: $col_black;
      display: flex;
      height: 100%;
      flex-direction: column;
      position: relative;
      padding-top: calc(56.25% + 30px);
      margin-top: calc(-56.25% - 30px);

      .vendor-name {
        font-size: 1.3rem;
        margin: 0 1.5rem 0.5rem 0;
        line-height: 1.6rem;
        text-align: left;
      }

      .service-times {
        margin-bottom: 0;
      }

      .call-to-action {
        display: block;
        padding: 1rem;
        font-weight: 500;
        text-align: center;
        color: $col_beta-darker;
        border-top: 3px solid $col_beta-darker;
        height: 60px;

        &.disabled {
          background: $col_disabled_gray;
          pointer-events: none;
          color: #9f9f9f;
          border-top: 3px solid #ccc;
        }
      }
      .material-icons-outlined {
        font-size: 18px;
        margin-right: 0.5rem;
      }
    }

    &.carousel .service-list_link {
      padding-top: 0;
      margin-top: 0;
    }

    .service-link:hover {
      .call-to-action {
        background: $col_beta-darker;
        color: #fff;
      }

      .call-to-action.disabled {
        background: $col_disabled_gray;
        cursor: default;
      }
    }
  }

  .service-list-icon {
    margin-right: 0.25rem;
  }

  .service-times {
    display: flex;
    align-items: center;
  }

  .service-times-icon {
    margin-right: 0.5rem;
    color: $col_alpha;
  }

  .image-container_outer {
    display: block;
    position: relative;
    overflow: hidden;
    padding-top: calc(56.25% + 30px);
    margin-bottom: -30px;
  }

  .image-container_inner {
    display: block;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;

    &.empty {
      .carousel {
        min-height: calc(100% - 30px);
        background-color: $col_offwhite;
      }
    }
  }

  .service-list_link_content_outer {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
  }

  .service-list_link_content_inner {
    padding: 1.3rem 1.25rem 2rem;
    flex-grow: 1;
    p {
      display: flex;
      font-weight: 300;
      font-size: 0.8rem;
      margin: 0;
      text-align: left;
    }
  }

  .busyness-indicator_outer {
    position: relative;
    height: 0;
    font-size: 0;
  }

  .busyness-indicator_inner {
    position: absolute;
    top: calc(56.25% - 35px);
    right: 0.5rem;
    left: auto;
    padding: 0.25rem 0.5rem 0.25rem 0.25rem;
    text-align: center;
    align-items: center;
    background: $col_alpha;
    color: $col_white;
    font-size: 0.8rem;
    gap: 0.25rem;
    border-radius: 5px;
    .mdi-icon {
      padding: 0.15rem;
    }
  }

  @media screen and (max-width: 639px) {
    .service-list_link_content_inner {
      padding: 1rem;
    }
  }

  .time-range {
    z-index: 5;
    font-weight: 400;
    display: block;
    line-height: 1.1rem;
    font-size: 0.85rem;
    color: $col_white;
  }

  .vendor-name {
    font-family: "Stolzl", san-serif;
    font-size: 1.25rem;
    font-weight: bold;
    .mobileLayout & {
      font-size: 1.17rem;
      display: block;
    }
  }
</style>
